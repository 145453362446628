import request from '@/utils/request'

// http://abc.api.com/login/login
export function login (data) {
    return request({
        url: '/login/login',
        method: 'post',
        data
    })
}
// http://abc.api.com/login/modify
export function edit_pwd(data) {
    return request({
        url: '/login/modify',
        method: 'post',
        data
    })
}

// http://abc.api.com/member/list?page=1&limit=30&keywords=18298127
// export function get_member_list (data) {
//     return request({
//         url: '/member/list',
//         method: 'get',
//         params: data
//     })
// }
// http://abc.api.com/order/list?type=1&start_date=&end_date=&page=1
// export function get_order_list (data) {
//     return request({
//         url: '/order/list',
//         method: 'get',
//         params: data
//     })
// }
// http://abc.api.com/account/list?limit=30&page=1
//用户列表
export function get_user_list (data) {
    return request({
        url: '/account/list',
        method: 'get',
        params: data
    })
}
//删除用户 
// http://abc.api.com/account/del
export function del_user (data) {
    return request({
        url: '/account/del',
        method: 'post',
        params: data
    })
}

// http://abc.api.com/account/create
// 创建用户编辑用户
export function edit_user_info (data) {
    return request({
        url: '/account/create',
        method: 'post',
        params: data
    })
}
//核销列表（包含历史数据）
// http://abc.api.com/write/list?keywords=18298127474&type=2&start_date=2023-09-23&end_date=2023-09-23
export function get_write_list (data) {
    return request({
        url: '/write/list',
        method: 'get',
        params: data
    })
}
// http://abc.api.com/order/member_list?member_id=1&limit=30&page=1
// 核销卡券
// http://abc.api.com/write/off
export function write_off (data) {
    return request({
        url: '/write/off',
        method: 'post',
        params: data
    })
}
//回撤卡券
// http://abc.api.com/write/reback
export function write_reback(data) {
    return request({
        url: '/write/reback',
        method: 'post',
        params: data
    })
}
// http://abc.api.com/order/list?type=1&start_date=&end_date=&page=1
export function get_order_list (data) {
    return request({
        url: '/order/list',
        method: 'get',
        params: data
    })
}
// http://abc.api.com/order/refund
export function order_refund(data) {
    return request({
        url: '/order/refund',
        method: 'post',
        params: data
    })
}

// ！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
//创建会员
// http://abc.api.com/member/create
export function member_create(data) {
    return request({
        url: '/member/create',
        method: 'post',
        params: data
    })
}
//会员列表
//http://abc.api.com/member/list?page=1&limit=30&keywords=18298127
export function get_member_list (data) {
    return request({
        url: '/member/list',
        method: 'get',
        params: data
    })
}
//获取会员二维码
export function get_qrcode (data) {
    return request({
        url: '/member/get_qrcode',
        method: 'post',
        data
    })
}
//删除会员
// http://abc.api.com/member/del
export function member_del(data) {
    return request({
        url: '/member/del',
        method: 'post',
        params: data
    })
}
//会员订单
//http://abc.api.com/order/member_list?member_id=1&limit=30&page=1
export function get_order_member_list(data) {
    return request({
        url: '/order/member_list',
        method: 'get',
        params: data
    })
}
//http://abc.api.com/member/coupon_list?member_id=1&limit=30&page=1
//会员卡券
export function get_member_coupon_list (data) {
    return request({
        url: '/member/coupon_list',
        method: 'get',
        params: data
    })
}
//http://abc.api.com/member/share_list?member_id=1&limit=30&page=1
//会员分销订单
export function get_member_share_list (data) {
    return request({
        url: '/member/share_list',
        method: 'get',
        params: data
    })
}
//创建亲属账户
// http://abc.api.com/member/add_relatives
export function member_add_relatives(data) {
    return request({
        url: '/member/add_relatives',
        method: 'post',
        params: data
    })
}
//亲属账户列表
//http://abc.api.com/member/relatives_list?limit=30&page=1&member_id=1
export function get_member_relatives_list (data) {
    return request({
        url: '/member/relatives_list',
        method: 'get',
        params: data
    })
}
//http://abc.api.com/member/del_relatives
// 删除亲属关系
export function member_del_relatives(data) {
    return request({
        url: '/member/del_relatives',
        method: 'post',
        params: data
    })
}
// ！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
// http://abc.api.com/coupon/create
//创建卡券
export function coupon_create(data) {
    return request({
        url: '/coupon/create',
        method: 'post',
        params: data
    })
}
//创建列表
export function banner_create(data) {
    return request({
        url: '/banner/create',
        method: 'post',
        params: data
    })
}
export function other_count(data) {
    return request({
        url: '/other/count',
        method: 'post',
        params: data
    })
}
// http://abc.api.com/other/count
//卡卷列表
// http://abc.api.com/coupon/list?limit=30&page=1&keywords=
export function get_coupon_list (data) {
    return request({
        url: '/coupon/list',
        method: 'get',
        params: data
    })
}

//banner列表
export function get_banner_list (data) {
    return request({
        url: '/banner/list',
        method: 'get',
        params: data
    })
}
//banner删除
export function banner_del(data) {
    return request({
        url: '/banner/del',
        method: 'post',
        params: data
    })
}


//删除卡卷
// http://abc.api.com/coupon/del
export function coupon_del(data) {
    return request({
        url: '/coupon/del',
        method: 'post',
        params: data
    })
}
//商品套餐！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
// http://abc.api.com/goods/create
//新建商品套餐
export function goods_create(data) {
    return request({
        url: '/goods/create',
        method: 'post',
        data
    })
}
// http://abc.api.com/goods/list?keywords=&limit=30&page=1
//套餐列表
export function get_goods_list (data) {
    return request({
        url: '/goods/list',
        method: 'get',
        params: data
    })
}
// http://abc.api.com/goods/del
//删除套餐
export function goods_del(data) {
    return request({
        url: '/goods/del',
        method: 'post',
        params: data
    })
}
//套餐上下架
// http://abc.api.com/goods/able
export function goods_able(data) {
    return request({
        url: '/goods/able',
        method: 'post',
        params: data
    })
}
//单套餐详情
//http://abc.api.com/goods/info
export function goods_info(data) {
    return request({
        url: '/goods/info',
        method: 'post',
        params: data
    })
}

//创建套餐内卡券!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//新建商品套餐
// http://abc.api.com/goods/create_coupon
export function goods_create_coupon(data) {
    return request({
        url: '/goods/create_coupon',
        method: 'post',
        params: data
    })
}
// http://abc.api.com/goods/list_coupon?goods_id=1&page=1&limit=30
// 套餐内卡券列表
export function get_goods_list_coupon (data) {
    return request({
        url: '/goods/list_coupon',
        method: 'get',
        params: data
    })
}
// http://abc.api.com/coupon/select
export function get_coupon_select (data) {
    return request({
        url: '/coupon/select',
        method: 'get',
        params: data
    })
}
// http://abc.api.com/goods/del_coupon
//删除套餐
export function goods_del_coupon(data) {
    return request({
        url: '/goods/del_coupon',
        method: 'post',
        params: data
    })
}
// http://abc.api.com/withdrawal/list?type=1&limit=30&page=1
export function withdrawal_list (data) {
    return request({
        url: '/withdrawal/list',
        method: 'get',
        params: data
    })
}
// http://abc.api.com/withdrawal/examine
export function withdrawal_examine(data) {
    return request({
        url: '/withdrawal/examine',
        method: 'post',
        params: data
    })
}





// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


